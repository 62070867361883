import { FEEDBACK_FORM, USER_MANUAL } from '../app/shared/constants';

export const environment = {
  env: 'uat',
  consentScopes: ['api://9cfdecb8-cb94-4955-a126-7cee71a15586/user_impersonation'],
  apiEndpoint: 'https://cigpeaccessapiuat.azurewebsites.net',
  graphEndpoint: 'https://graph.microsoft.com/v1.0',
  clientId: '59aeb6b1-76ed-4cbb-8844-634ff4cffb89',
  tenant: '6f41eebe-f4f6-41f6-9d01-03d70104f18c',
  userManual: USER_MANUAL,
  feedbackForm: FEEDBACK_FORM
};
